import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtenerCajas() {
    var url = ConfigAPI.baseURL + "finances-cash" + Session.getToken();
    return instance.get(url);
  },
  obtenerSaldoCuentaCorriente(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "sales-currents-accounts/filter/query/total" + Session.getToken();
    return instance.post(url,params);
  },   
  obtenerChequesByType(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "finances-cheques/filter/query/type" + Session.getToken();
    return instance.post(url,params);
  },    
  obtenerAccountingAccountCheque(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "accounting-settings/filter/query/reference" + Session.getToken();
    return instance.post(url,params);
  },  

  mostrarStaff(id) {
    var url = ConfigAPI.baseURL + "staff/" + id + Session.getToken();
    return instance.get(url);
  },    
}

export default servicesAPI;