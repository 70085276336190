<template>    
  <div>
    <b-input-group size="sm" :prepend="getCurrencySymbol">
      <b-form-input v-model="crud.form.usageBalance" 
                    type="number"
                    step="0.01"                    
                    :readonly="true"
                    class="input-cash-total"
                    @change="openCrud()">
      </b-form-input>      

      <b-input-group-append>          
        <b-button variant="outline-info" 
                  @click="openCrud()"
                  title="Utilizar saldo a favor"
                  class="button-cash-open">
          <b-icon icon="circle-fill" variant="success" animation="throb" font-scale="1" v-if="crud.form.positiveBalance>0"></b-icon>
          Saldo a Favor
          <b-icon icon="chevron-double-right"></b-icon>
        </b-button>
      </b-input-group-append>                         
    </b-input-group>

    <!-- ########################### -->
    <!-- #####     MODALES     ##### -->
    <!-- ########################### -->

    <!-- CRUD MAIN -->  
    <b-modal v-model="modal.form.active"
            header-bg-variant="dark"
            header-text-variant="white">
      <div slot="modal-header">
        {{this.modal.form.title}}
      </div>
      
      <b-row :key="keyForceUpdate" align-h="center" v-if="crud.form.positiveBalance">   
        <b-col md="6">     
          <b-row>
            <b-col md="12">
              <b-form-group label="Saldo Disponible" description="Saldo a favor en cuenta corriente">
                <b-input-group size="sm" prepend="$" append=".00">
                  <b-form-input type="number"
                                step="0.01"
                                size="sm"
                                :readonly="true"
                                v-model="crud.form.positiveBalance">
                  </b-form-input>              
                </b-input-group>
              </b-form-group>
            </b-col>              
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-group label="Saldo a Utilizar" description="Saldo a utilizar del disponible">
                <b-input-group size="sm" prepend="$" append=".00">
                  <b-form-input type="number"
                                step="0.01"
                                size="sm"
                                v-model="crud.form.usageBalance"
                                placeholder="Importe a Utilizar">
                  </b-form-input>              
                </b-input-group>
              </b-form-group>                
            </b-col>
          </b-row>      
        </b-col>
      </b-row>
      <b-row v-else>   
        <b-col>
          <b-alert show variant="warning">
            No posee Saldo a Favor
          </b-alert>
        </b-col>
      </b-row>
    
      <div slot="modal-footer">
        <b-button variant="outline-secondary" class="mr-1" @click="cancel()">Cancelar</b-button>
        <b-button variant="dark" @click="save()">Aceptar</b-button>          
      </div>

    </b-modal>
  </div>
</template>
<script>  
  import serviceAPI from './../services'
  import ErrorToken from '@/handler/errorToken'
  import Helper from '@/handler/helper'
  import FindObject from '@/components/inc/find/findObject'  

  export default {
    props:{
      customers_id: {
        type: Number,
        default: 0,
        required: true,
      },
      points_sales_id: {
        type: Number,
        default: 0,
        required: true,
      },      
      reset: {
        type: Boolean,
        default: false
      }                
    },
    components: {
      FindObject,      
    },    
    data: () => {
      return { 
        crud: {
          form: {
            positiveBalance: 0,
            usageBalance: 0,                           
          },
        },             
        modal: {
          form: {
            active: false,
            title: ''
          },          
        },   
        keyForceUpdate: 0,        
      }
    }, 
    computed: {
      // CURRENCY
      getCurrencySymbol() {
        return '$'
      },
      getCurrencyCode() {
        return 'ARS'
      }      
    },
    watch: {        
      'reset': function (newQuestion, oldQuestion) {    
        if(newQuestion == true) {          
          this.crud.form = {
            positiveBalance: 0,
            usageBalance: 0,
          },
          this.$emit('getFinancesPositiveBalance', {
            total: this.crud.form.usageBalance
          })           
        }
      },
      'customers_id': function (newQuestion, oldQuestion) {    
        this.searchPositiveBalance()
      },      
      'points_sales_id': function (newQuestion, oldQuestion) {            
        this.searchPositiveBalance()
      },            
    },      
    mounted() {
      this.searchPositiveBalance()
    },
    methods: {        
      // CRUD   
      searchPositiveBalance() {
        var result = serviceAPI.obtenerSaldoCuentaCorriente({
          customers_id: this.customers_id,
          points_sales_id: this.points_sales_id,
        })

        result.then((response) => {
          var data = response.data
          if(data<0) {
            this.crud.form.positiveBalance = Math.abs(data)            
            this.forceUpdate()                      
          } 
        })   
      },
      openCrud() {      
        if(!this.crud.form.usageBalance) {
          this.crud.form.usageBalance = this.crud.form.positiveBalance
        }
        
        this.modal.form.title = "Saldo a Favor"
        this.modal.form.active = true        
      },
      save() {        
        this.$emit('getFinancesPositiveBalance', {
          total: this.crud.form.usageBalance
        })      
        this.modal.form.active = false   
      },       
      cancel() {        
        this.crud.form.usageBalance = 0
        this.$emit('getFinancesPositiveBalance', {
          total: 0
        })      
        this.modal.form.active = false      
      },
      forceUpdate() {
        this.keyForceUpdate = this.keyForceUpdate + 1
      },           
    }
  }
</script>
<style>
  .crud-chas-add {
    margin-top: 30px;
  }
  .table-cash-sub {
    min-height: 100px;
  }
  .button-cash-open {
    min-width: 135px;
    font-weight: 800;
    text-align: right;
  }     
  .input-cash-total {
    font-weight: 800;
  } 
</style>